<template>
    <div class="interactive-manage">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
            <el-breadcrumb-item class="first">工作台</el-breadcrumb-item>
            <el-breadcrumb-item class="second">帮助中心</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="interactive-content">
            <!--<div class="help-search">-->
                <!--<span class="text">帮助中心</span>-->
                <!--<el-input-->
                        <!--placeholder="请输入内容"-->
                        <!--prefix-icon="el-icon-search"-->
                        <!--v-model="searchInput"-->
                        <!--@keydown.enter.native="searchInputChange"-->
                        <!--class="search-input"-->
                        <!--style="width: 320px;">-->
                <!--</el-input>-->
            <!--</div>-->
            <div class="help-content">
                <div class="help-class">
                    <div class="help-class-ul"  v-for="item in helpClass">
                        <div class="super" @click="viewChild(item)">
                            <span class="text">{{item.name}}</span>
                            <i class="iconfont"
                               v-if="item.children"
                               :class="{current_i: currentSuper === item.id}"
                            >&#xe8f1;</i>
                        </div>
                        <div class="help-class-item"
                             v-if="currentSuper === childItem.super_id"
                             v-for="childItem in item.children"
                             :key="childItem.id"
                             :class="{current: currentClass === childItem.id}"
                             @click="chooseClass(childItem)">
                            <span class="text">{{childItem.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="help-list-content">
                    <div class="class-text">{{currentClassName}}</div>
                    <div class="help-list">
                        <div class="help-list-item" v-for="item in articleList" @click="viewArticleDetail(item)">
                            <span class="article-title">{{item.title}}</span>
                            <i class="iconfont">&#xe8f1;</i>
                        </div>
                        <div class="no-data" v-if="articleList.length === 0">
                            <span>暂无数据</span>
                        </div>
                    </div>
                    <el-button type="primary" class="help-btn" @click="back">返回</el-button>
                </div>
            </div>
        </div>
        <!--查看详情弹窗-->
        <el-dialog :title="articleTitle"
                   :visible.sync="dialogArticleDetail"
                   width="900px"
                   class="article-dialog"
                   :close-on-click-modal="false"
                   @close="handleClose">
            <div class="article-content">
                <div v-if="articleContent">
                    <div v-html="articleContent"></div>
                </div>
                <div v-if="video_path" class="video-show">
                    <video  :src="video_path" controls="controls"></video>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "HelpCenter",
        data() {
            return {
                //搜索
                searchInput: '',
                //帮助中心分类
                helpClass: [],
                //选中的分类
                currentClass: '',
                currentClassName: '',
                currentSuper: '',
                //文章列表
                articleList: [],
                dialogArticleDetail: false,
                //文章标题
                articleTitle: '',
                //文章内容
                articleContent: '',
                //视频路径
                video_path: ''
            }
        },
        created() {
          this.getHelpClassList();
        },
        methods: {
            //获取帮助中心分类
            getHelpClassList() {
                this.$httpStudent.axiosGet(this.$api.helpClassification, (res) => {
                    if (res.code === 200) {
                        if (res.data.data.length > 0) {
                            this.helpClass = res.data.data;
                            // this.currentClass = res.data[0].id;
                            // this.currentClassName = res.data[0].name;
                            this.getHelpInfo();
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //搜索
            searchInputChange() {
                if (this.searchInput) {
                    if (this.searchInput.length > 40) {
                        this.$message.warning('搜索名称，1-40个字符');
                    } else {
                        this.getHelpInfo();
                    }
                }
            },
            //选择分类
            chooseClass(item) {
                this.currentClass = item.id;
                this.currentClassName = item.name;
                this.getHelpInfo();
                this.getHelpVideoList();
            },
            viewChild(item) {
                this.currentSuper = item.id;
                this.currentClass = item.id;
                this.currentClassName = item.name;
                this.getHelpInfo();
                this.getHelpVideoList();
            },
            //获取帮助中心问题信息
            getHelpInfo() {
                let param = {
                    help_id: this.currentClass
                }
                if (this.searchInput) {
                    param.title = this.searchInput;
                }
                this.$httpStudent.axiosGetBy(this.$api.help, param, (res) => {
                    if (res.code === 200) {
                        this.articleList = res.data.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            getHelpVideoList() {
                this.$httpStudent.axiosGet(this.$api.help_video, (res) => {
                    if (res.code === 200) {
                        res.data.data.forEach((item) => {
                            if (item.classification_id === this.currentClass) {
                                this.articleList.push(item);
                            }
                        })
                        // this.articleList = res.data.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //查看详情
            viewArticleDetail(item) {
                this.dialogArticleDetail = true;
                this.articleTitle = item.title;
                if (item.video_path) {
                    this.video_path = item.video_path;
                } else {
                    let param = {
                        id: item.id
                    }
                    this.$httpStudent.axiosGetBy(this.$api.help, param, (res) => {
                        if (res.code === 200) {
                            this.articleContent = res.data.content;
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //关闭弹窗
            handleClose() {
                this.dialogArticleDetail = false;
                this.articleTitle = '';
                this.articleContent = '';
                this.video_path = '';
            },
            //返回
            back() {
                this.$router.push({
                    path: '/student/works/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .interactive-manage {
        min-height: calc(100vh - 100px);
    }
    .help-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
            display: block;
        }
        .search-input {
            width: 320px;
            ::v-deep .el-input__inner {
                border-radius: 18px;
            }
        }
    }
    .help-content {
        display: flex;
        min-height: calc(100vh - 192px);
        margin-top: 17px;
        .help-class {
            width: 208px;
            min-height: 440px;
            background-color: #fff;
            border-radius: 10px;
            margin-right: 17px;
            .help-class-ul {
                color: #5F5C74;
                .super {
                    display: flex;
                    justify-content: space-between;
                    height: 48px;
                    line-height: 48px;
                    cursor: pointer;
                    .text {
                        padding-left: 20px;
                    }
                    i {
                        width: 48px;
                        padding-right: 10px;
                        text-align: center;
                    }
                    i.current_i {
                        transform: rotate(90deg);
                    }
                }
            }
            .help-class-item {
                height: 48px;
                line-height: 48px;
                color: #5F5C74;
                cursor: pointer;
                .text {
                    padding-left: 39px;
                }
                &:hover {
                    background-color: #EDF7E1;
                }
            }
            .current {
                background-color: #EDF7E1;
                &:before {
                    content: '';
                    position: absolute;
                    width: 3px;
                    height: 48px;
                    background-color: #9ACC5B;
                }
            }
        }
        .help-list-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 1%;
            background-color: #fff;
            border-radius: 10px;
            padding: 22px 40px;
            box-sizing: border-box;
            .class-text {
                padding-bottom: 20px;
                border-bottom: 1px solid #EEEEEE;
                color: #5F5C74;
                font-size: 16px;
            }
            .help-list {
                height: calc(100% - 103px);
                .help-list-item {
                    &:last-child {
                        margin-bottom: 10px;
                    }
                    height: 56px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #EEEEEE;
                    cursor: pointer;
                    .article-title {
                        color: #3A3567;
                        font-size: 16px;
                    }
                    i {
                        color: #7D7D7D;
                    }
                    &:hover {
                        .article-title {
                            color: #564CED;
                        }
                        i {
                            color: #564CED;
                        }
                    }
                }
            }
            .help-btn {
                width: 100px;
                background-color: #564CED;
                border-color: #564CED;
                margin: 22px auto 0;
                &:hover {
                    background-color: #1b2dbf;
                    border-color: #1b2dbf;
                }
            }
        }
    }
    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #5F5C74;
    }
    .article-dialog {
        ::v-deep .el-dialog {
            border-radius: 10px;
            ::v-deep .el-dialog__header {
                background-color: #F1F0FE;
                border-radius: 10px 10px 0 0;
            }
            ::v-deep .el-dialog__body {
                height: 500px;
                padding: 20px;
                .article-content {
                    height: 100%;
                    overflow: auto;
                    p {
                        margin: 0 10px 10px 10px;
                        overflow: auto;
                    }
                    .video-show {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        video {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>